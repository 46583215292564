import {
  Button,
  cn,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  UseDisclosureProps,
} from '@nextui-org/react';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import { modalStyle, primaryButton } from '@/theme/nextUI';
import PhoneInput from '../PhoneInput';
import { useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import nodeAPI from '@/lib/nodeapi';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import { OTPInput, REGEXP_ONLY_DIGITS } from 'input-otp';

const WhatsappLoginModal = ({
  disclosure: { isOpen, onClose },
}: {
  disclosure: UseDisclosureProps;
}) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('jo');
  const [code, setCode] = useState('');
  const [isIncorrectCode, setIsIncorrectCode] = useState(false);
  const [setp, setSetp] = useState(1);
  const [retryinterval, setRetryInterval] = useState(1);
  const SESSION_ID = useMemo(() => crypto.randomUUID(), []);

  const [countDown, setCountDown] = useState<number | undefined>();
  const countDownRef = useRef<any>(null);

  const { mutate: sendOtp, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const pn = parsePhoneNumber('+' + phone, {
          defaultCountry: phoneCountry?.toUpperCase() as CountryCode,
        });

        if (!pn.isValid()) {
          return;
        }
      } catch (error) {
        toast.error(t('Invalid phone number'));
        return;
      }

      const { status, message } = await nodeAPI(
        'POST',
        '',
        'auth/sendLoginOtp',
        {
          sessionId: SESSION_ID,
          phoneNumber: phone,
        },
      );

      setCountDown(new Date().getTime() + 1000 * 60 * retryinterval);
      countDownRef.current?.start();

      if (!status) {
        showCodeError();

        switch (message) {
          case 'REQUIRED':
            toast.error(t('Phone number is required'));
            return;

          case 'OTP_ALREADY_SENT':
            toast.error(
              t(
                'OTP already sent, please wait a bit before requesting another',
              ),
            );
            return;

          default:
            toast.error(t('Something went wrong!'));
            return;
        }
      }

      setSetp(2);
    },
  });

  const { mutate: validateOtp, isPending: isPendingValidation } = useMutation({
    mutationFn: async () => {
      const { status, message } = await nodeAPI(
        'POST',
        '',
        'auth/validateLoginOtp',
        {
          sessionId: SESSION_ID,
          phoneNumber: phone,
          otp: code,
        },
      );

      if (!status) {
        showCodeError();

        switch (message) {
          case 'INCORRECT_OTP':
            toast.error(t('Incorrect OTP'));
            return;

          default:
            toast.error(t('Something went wrong!'));
            return;
        }

        return;
      }

      await signIn('credentials', {
        phoneNumber: phone,
        otp: code,
        countryCode: phoneCountry,
        sessionId: SESSION_ID,
        callbackUrl: '/',
      });

      await new Promise((resolve) => setTimeout(resolve, 500));

      onClose?.();
    },
  });

  const showCodeError = () => {
    setIsIncorrectCode(true);
    setTimeout(() => {
      setIsIncorrectCode(false);
    }, 2500);

    return;
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed)
      return (
        <Link
          className="cursor-pointer"
          onPress={() => {
            setRetryInterval((v) => (v == 1 ? 3 : v == 3 ? 5 : v));
            sendOtp();
          }}
        >
          {t('Resend Code')}
        </Link>
      );

    return (
      <p>
        {t('Please wait')}
        <span className="ordinal slashed-zero tabular-nums">
          {minutes > 0
            ? ` ${minutes.toString().padStart(2, '0')} ${t('minutes')} ${t('and')}`
            : ''}
          {` ${seconds.toString().padStart(2, '0') + ' ' + t('seconds')} `}
        </span>
        {t('before requesting another code.')}
      </p>
    );
  };

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose} classNames={modalStyle}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Whatsapp Login')}</h5>
        </ModalHeader>
        <ModalBody>
          {setp == 1 && (
            <div className="flex flex-col gap-1 w-full">
              <PhoneInput
                phone={phone}
                setPhone={setPhone}
                phoneCountry={phoneCountry}
                setPhoneCountry={setPhoneCountry}
                label="Whatsapp Number"
                placeholder="Enter Whatsapp number"
                onEnter={() => sendOtp()}
              />
            </div>
          )}
          {setp == 2 && (
            <>
              <p className="mb-3">
                {t(`We've sent a security code to`)}
                <b dir="ltr">{` +${phone || ''}, `}</b>
                {t(`Please enter it below to login`)}
              </p>

              <div className="flex flex-col items-center justify-center gap-3">
                <OTPInput
                  value={code}
                  onChange={(v) => setCode(v)}
                  maxLength={6}
                  pattern={REGEXP_ONLY_DIGITS}
                  className="!w-full"
                  textAlign="center"
                  inputMode="numeric"
                  render={({ slots }) => (
                    <div className="flex items-center justify-center" dir="ltr">
                      {slots.slice(0, 6).map(({ isActive, char }, idx) => (
                        <div
                          key={idx}
                          className={cn(
                            'relative w-10 h-14 text-[2rem]',
                            'flex items-center justify-center',
                            'transition-all duration-300',
                            'border-jacarta-200 dark:border-jacarta-600 border-y border-e first:border-s first:rounded-s-md last:rounded-e-md',
                            'group-hover:border-ring-accent group-focus-within:border-ring-accent ring-inset',
                            { 'ring-2 ring-accent': isActive },
                            {
                              'border-red-500 ring-red-500 bg-red-50/75':
                                isIncorrectCode,
                            },
                          )}
                        >
                          {char !== null && <div>{char}</div>}
                        </div>
                      ))}
                    </div>
                  )}
                  onComplete={(code) => {
                    setCode(code);
                    validateOtp();
                  }}
                />

                <Countdown
                  key={countDown}
                  // @ts-ignore
                  innerRef={countDownRef}
                  date={countDown}
                  renderer={renderer}
                />
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {setp == 1 && (
            <Button
              className={primaryButton}
              onPress={() => sendOtp()}
              isLoading={isPending}
            >
              {t('Next')}
            </Button>
          )}
          {setp == 2 && (
            <>
              <Button
                variant="bordered"
                className="font-semibold"
                onClick={() => setSetp(1)}
                isDisabled={isPendingValidation}
              >
                {t('Back')}
              </Button>
              <Button
                className={primaryButton}
                onClick={() => validateOtp()}
                isLoading={isPendingValidation}
              >
                {t('Login')}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WhatsappLoginModal;
