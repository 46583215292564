import { Button, useDisclosure, Image, Link } from '@nextui-org/react';
import { signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Meta from '@/components/Meta';
import isRunningInWebView from '@/lib/utils';
import { FormInput } from '@/components/shared/FormInput';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import WhatsappLoginModal from '@/components/modal/WhatsappLoginModal';

const Login = () => {
  const { t } = useTranslation();

  const whatsappDisclosure = useDisclosure();

  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    setIsWebView(isRunningInWebView());
  }, []);

  async function handleGoogleSignin() {
    await signIn('google', { callbackUrl: window.location.href });
  }

  async function handleFacebookSignin() {
    signIn('facebook', { callbackUrl: '/' });
  }

  return (
    <>
      <Meta title={` ${t('Login_Title')} | ${t('Gamerg-GG')} `} />

      <div className="h-[90vh] xl:h-[95vh] flex-center md:bg-white md:dark:bg-jacarta-800 bg-[url(/images/login/background-image.webp)] xl:bg-[/images/login/background-image.webp)] bg-cover w-full">
        <div className="p-1 md:p-2 flex-center gap-5 mx-auto h-fit md:w-full md:h-full xl:w-[70rem] xl:shadow-large xl:h-[75vh] xl:max-h-[50rem] rounded-xl md:rounded-none xl:rounded-xl bg-white dark:bg-jacarta-800">
          <div className="relative hidden lg:flex h-full flex-1 self-start max-h-[60rem]">
            <Image
              src="/images/login/login-image.webp"
              classNames={{
                wrapper: 'w-full h-full',
                img: 'h-full w-full object-cover rounded-xl',
              }}
              className="w-full"
              alt="Login Background"
            />

            <div className="absolute start-0 bottom-0 p-5  space-y-2 text-white z-20 capitalize text-4xl font-semibold">
              <p className=" text-balance leading-none">
                {t('Join GamerG & win')}
              </p>
              <p className="leading-none">{t('everyday')}</p>
            </div>
          </div>

          <div className="flex-center md:w-[50%] md:-mt-32">
            <div className="space-y-5 py-10 px-10 rounded-md ">
              <div className="flex flex-col items-center justify-center mb-10">
                <Image
                  src="/images/logo_white.png"
                  alt="Gamerg.gg logo"
                  className="hidden dark:block max-h-28 md:max-h-36"
                />
                <Image
                  src="/images/logo.png"
                  alt="Gamerg.gg logo"
                  className="block dark:hidden max-h-28 md:max-h-36"
                />

                <h2 className="font-semibold text-2xl sm:text-4xl">
                  {t('Ready to win')}
                </h2>
              </div>

              {isWebView && (
                <div
                  className="bg-red border border-red text-white px-4 py-3 rounded relative mb-3 text-justify"
                  role="alert"
                >
                  {t('Login_Error')}

                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
                </div>
              )}

              <div className="flex flex-col gap-2 w-full">
                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onClick={() => whatsappDisclosure.onOpen()}
                  startContent={
                    <Image
                      src="/images/social/whatsapp.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Whatsapp"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Whatsapp')}
                  </span>
                </Button>

                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onClick={handleGoogleSignin}
                  disabled={isWebView}
                  startContent={
                    <Image
                      src="/images/social/google.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Google"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Google')}
                  </span>
                </Button>

                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onClick={handleFacebookSignin}
                  disabled={isWebView}
                  startContent={
                    <Image
                      src="/images/social/facebook.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Facebook"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Facebook')}
                  </span>
                </Button>
              </div>

              <div className="text-center">
                <p>{t('By logging in, you agree to GamerG.gg')}</p>
                <Link className="cursor-pointer" href="/privacy-policy">
                  {t('Privacy_Policy')}
                </Link>

                <span className="px-1">{t('and')}</span>
                <Link className="cursor-pointer" href="/terms-and-conditions">
                  {t('Terms_and_Conditions')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhatsappLoginModal disclosure={whatsappDisclosure} />
    </>
  );
};

export default Login;

const LoginEmailForm = () => {
  const [isVisible, setIsVisible] = useState(false);

  const togglePasswordVisibility = () => setIsVisible((prev) => !prev);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object({
        password: yup.string().required().label(t('Password')),
        email: yup.string().email().label(t('Verified Email')),
      }),
    ),
  });
  const onSubmit = (data) => {};
  return (
    <form
      className="flex-1 flex flex-col gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        name={'email'}
        label={t('Email')}
        variant={'bordered'}
        control={control}
        placeholder={t('Enter your email')}
      />
      <FormInput
        name={'password'}
        label={t('Password')}
        variant={'bordered'}
        control={control}
        type={isVisible ? 'text' : 'password'}
        placeholder={t('Enter your password')}
        endContent={
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="focus:outline-none"
          >
            {isVisible ? <FiEye /> : <FiEyeOff />}
          </button>
        }
      />
      <Button
        size="md"
        radius="md"
        type="submit"
        variant="faded"
        className="font-semibold"
      >
        {t('Sign in')}
      </Button>
    </form>
  );
};
